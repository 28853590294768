import React from 'react';
import './styles.css';
import ParticleImage, {
  forces
} from "react-particle-image";

var isDarkMode = false;

function App() {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const [isParticleHovered, setIsParticleHovered] = React.useState(false);
  const [isMouseMoving, setIsMouseMoving] = React.useState(false);
  const mouseTimeoutRef = React.useRef(null);

  const particleOptions = {
    filter: ({ x, y, image }) => {
      const pixel = image.get(x, y);
      return pixel.b < 30;
    },
    color: ({ x, y, image }) => isDarkMode ? "#ffffff" : "#121212",
    radius: () => 0.75,
    mass: () => 100,
  };

  const motionForce = (x, y) => {
    return forces.disturbance(x, y, 50);
  };

  const sections = [
    { id: 'home', label: 'Home' },
    { id: 'solutions', label: 'Solutions' },
    { id: 'technology', label: 'Technology' },
    { id: 'team', label: 'Team' },
  ];

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  React.useEffect(() => {
    isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  }, []);

  React.useEffect(() => {
    const handleMove = () => {
      setIsMouseMoving(true);

      if (mouseTimeoutRef.current) {
        clearTimeout(mouseTimeoutRef.current);
      }

      mouseTimeoutRef.current = setTimeout(() => {
        setIsMouseMoving(false);
      }, 100);
    };

    window.addEventListener('mousemove', handleMove);
    window.addEventListener('touchmove', handleMove);

    return () => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('touchmove', handleMove);
      if (mouseTimeoutRef.current) {
        clearTimeout(mouseTimeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="app">
      {/* <nav className="navbar">
        <div className="logo">prefix</div>
        <div className="nav-links">
          {sections.map(({ id, label }) => (
            <button
              key={id}
              onClick={() => scrollToSection(id)}
              className="nav-link"
            >
              {label}
            </button>
          ))}
        </div>
      </nav>

      <div className="gradient-blur">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div> */}

      <main style={{ paddingTop: 'var(--navbar-height)' }}>
        <section id="home" className="hero-section" style={{ paddingTop: '2rem' }}>
          <h1
            style={{
              opacity: (isParticleHovered && isMouseMoving) ? 0 : 1,
              transition: (isParticleHovered && isMouseMoving) ? 'opacity 0.3s' : 'opacity 1s 1s'
            }}
          >
            prefix
          </h1>
          <div
            onMouseEnter={() => setIsParticleHovered(true)}
            onMouseLeave={() => setIsParticleHovered(false)}
            onTouchStart={() => setIsParticleHovered(true)}
            onTouchEnd={() => setIsParticleHovered(false)}
          >
            <ParticleImage
              style={{
                opacity: !(isParticleHovered && isMouseMoving) ? 0 : 1,
                transition: !(isParticleHovered && isMouseMoving) ? 'opacity 2s 1s' : 'opacity 0.3s'
              }}
              src={"/prefix-logo.png"}
              entropy={0}
              maxParticles={isSafari ? 6_000 : 12_000}
              particleOptions={particleOptions}
              mouseMoveForce={motionForce}
              touchMoveForce={motionForce}
              backgroundColor={isDarkMode ? "#121212" : "#ffffff"}
              width={600}
              height={300}
              scale={0.33}
            />
          </div>
        </section>

        {/* <section id="solutions" className="solutions-section">
          <h2>Solutions</h2>
        </section>

        <section id="technology" className="technology-section">
          <h2>Technology</h2>
        </section>

        <section id="team" className="team-section">
          <h2>Team</h2>
        </section> */}
      </main>

      <footer className="footer">
        <div className="footer-content">
          {/* Add footer content here */}
        </div>
      </footer>
    </div>
  );
}

export default App;
